import React, { useLayoutEffect, useRef } from 'react'
import Banner from './banner';
import Features from './features';
import Feedback from './feeback';
import Footer from './footer';
import Navbar from './navbar';
import Services from './services';
import styles from './index.module.scss'
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function Index() {
    return (
        <>
            <Navbar />
            <div className={cx('container')}>
                <div className={cx('body')}>
                    <Banner />
                    <Services />
                    <Features />
                    <Feedback />
                </div>
                <Footer />
            </div>
            <div className={cx('bg-footer')}>
                <img src='/imgs/mail/footer-bg.svg' alt='' className={cx('image-bg')} />
            </div>
        </>
        
    )
}

export default Index