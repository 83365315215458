import React from 'react'
import styles from './Footer.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

function Footer() {
    return (
        <footer id="contact-us" className={cx('footer')}>
            <h3>ALL IN ONE MAIL</h3>
            <div className={cx('contact')}>
                <div className={cx('form')}>
                    <p>Contact us</p>
                    <input type='text' placeholder='Your email' className={cx('input')} />
                    <textarea placeholder='Content' className={cx('input')}></textarea>
                    <button>Send</button>
                </div>
                <img src='/imgs/mail/footer.svg' alt='footer' />
            </div>
        </footer>
    )
}

export default Footer